import { useDispatch, useSelector } from "react-redux"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { setFlyoutContentURIAction, toggleFlyoutVisibilityAction } from "@redux/actions/application"
import { isFlyoutVisible } from "@redux/selector/applicationStates"
import { useToggleState } from "@services/hooks/useToggleState"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { MODULE_FAQ_AVAILABLE } from "config"

import { FAQKeys } from "./FAQHelper"
import ActionIcon from "../ActionIcon"
import HtmlContent from "../HtmlContent"


const usedNamespaces: NamespaceShortcut[] = ["faq", "faq-meta"]

/**
 * exportable interface, to integrate the faqpopup more easy into formik-elements or other components
 */
export interface IFAQPopupProps {
  /**
   * Key within the FAQ namespace (without the namespace prefix "faq:" itself)
   * e.g. 'introduction.projects.roles'
   */
  faqKey: string
  /**
   * Optional className for the ActionIcon that triggers the FAQPopup
   */
  className?: string
}

/**
 * This component provides a standard-usage of an PopUp for FAQ information. It is part of the FAQ-System.
 *
 * The component does not reuse the FAQEntryLayer-Component: it simply chooses an entry and displays a link
 * for more detailed information to the FAQComponent in the Flyout.
 *
 * Usage:
 * <FAQPopup key="introduction.projects.dashboard" />
 *
 * Recommendation for usage of FAQPopup:
 * => Definition of one or multiple terms/concepts
 * => Any Definition or explanation should be short and simple
 * => Ask yourself:
 * 1) "Do I have terms to explain?" -> Yes? Go to second question:
 * 2) "If you delete the Popup, would you still get the necessary information from the context of the page" -> No? Use the Popup!
 *
 * Don't use FAQPopup for explanation on how to use a function or the platform. It's not an introduction or tutorial tool.
 * Don't use it, if it's not more than 4 words (use Tooltip instead!).
 *
 * Hint for using Images, Video's or other Mediatypes: As a redacteur use HTML in the translation.json.
 *
 * @todo Default size of FAQPopUp, add scrollbar?
 */
const FAQPopup: React.FC<IFAQPopupProps> = (props: IFAQPopupProps) => {
  const {
    faqKey,
    className
  } = props

  const dispatch = useDispatch()
  const flyoutIsVisible = useSelector(isFlyoutVisible)

  const t = useDynamicTranslation()

  // stores if a popup is visible or not
  const { isTrue: popupOpen, setIsTrue: setPopupOpen } = useToggleState(false)

  return (MODULE_FAQ_AVAILABLE && faqKey) && <>
    <ActionIcon
      aria-label={t("faq", faqKey + "." + FAQKeys.Question)} // to give blind people content context
      onclick={() => setPopupOpen(!popupOpen)}
      className={className}
      title={t("faq-meta", "flyout.open")}
      icon="question-mark-circle"
    />

    <Modal
      isOpen={popupOpen}
      toggle={() => setPopupOpen(false)}
    >
      <ModalHeader>
        <HtmlContent content={t("faq", faqKey + "." + FAQKeys.Question)} />
      </ModalHeader>

      <hr className="popup__hr" />

      <ModalBody className="faq-popup__body">
        <HtmlContent content={t("faq", faqKey + "." + FAQKeys.Answer)} />
      </ModalBody>

      <ModalFooter className="faq-popup__footer">
        <HtmlContent
          content={t("faq-meta", "additionalInformation", {
            topic: t("faq", faqKey.substring(0, faqKey.lastIndexOf(".")) + "." + FAQKeys.Title)
          })}
        />
        {
          // If the FAQFlyoutTrigger is clicked, the modal will be closed and the Flyout
          // will open to show one level higher than the given faqKey.
        }
        <ActionIcon
          title={t("faq-meta", "flyout.open")}
          icon="question-mark-circle"
          onclick={() => {
            // close popup
            setPopupOpen(false)
            // open flyout with FAQ with content of one level higher than the faqKey
            dispatch(
              setFlyoutContentURIAction(faqKey.substring(0, faqKey.lastIndexOf(".")))
            )
            if (!flyoutIsVisible) {
              // change flyout visibility only, if it is not visible yet
              // otherwise an already open flyout would close if the user clicks on this ActionIcon
              dispatch(toggleFlyoutVisibilityAction())
            }
          }}
        />
      </ModalFooter>
    </Modal>
  </>
}

export default withDynamicNamespaces<IFAQPopupProps>(FAQPopup, usedNamespaces)