
import { NextRouter } from "next/router"

import { IRIstub } from "@api/schema"
import { BackendRoutes, Routes } from "@basics/routes"
import { stringToId } from "@basics/util-importless"


import { useWindowLocation } from "./windowHooks"


// #region helper functions for routes

/**
 * Interface for URL params
 */
interface IUrlParam {
  name: string
  value: string | number
}

/**
 * Creates a valid URL from a given route and given parameters, attached to this route
 *
 * @param route a valid route from pre-defined possible Routes
 * @param params a set of parameters
 * @param redirectBack a link, where the user should be sended AFTER finishing the job on the route-URL (must be implemented when needed on the route-url-page!)
 * @returns a valid URL set up from route and params
 */
export const routeWithParams = (
  route: Routes | BackendRoutes,
  params: { [param: string]: string | number },
  redirectBack?: string,
  urlParams?: IUrlParam | IUrlParam[]
): IRIstub => {
  let resultingRoute: IRIstub = route
  // replace all params-keys, exististing in the pattern "[key]" in the route
  // by the value of the param-key, e.g.
  // params: {non: "sense"}
  // will replace all "[non]" elements in the route string by "sense"
  if (params) {
    Object.keys(params).forEach((name) => {
      // If input type is not of type IRIstub, the function could return inconsistent data.
      // The result is not less type safe than the input.
      resultingRoute = resultingRoute.replace(`[${name}]`, encodeURIComponent(params[name].toString())) as IRIstub
    })
  }

  // continue to work with an actual URL, handing in a dummy host if route is just an absolute path w/o schema/host
  // Note: the dummy_host variable has to have a valid protocol, otherwise an error: `Invalid Url`is thrown
  const DUMMY_HOST = "http://dummy" // a string that surely will never appear as an input value (also not in tests!)
  const url = new URL(resultingRoute, DUMMY_HOST)

  // handle redirectBack url param
  if (redirectBack) {
    url.searchParams.append("redirectBack", redirectBack)
  }

  // handle all other url params
  if (urlParams) {
    if (Array.isArray(urlParams)) {
      urlParams.forEach(param => {
        url.searchParams.append(param.name, encodeURIComponent(param.value))
      })
    } else {
      url.searchParams.append(urlParams.name, encodeURIComponent(urlParams.value))
    }
  }

  // remove dummy host again, if route was an absolute path without scheme/host
  resultingRoute = url.toString() as IRIstub
  return resultingRoute.startsWith(DUMMY_HOST) ? resultingRoute.substring(DUMMY_HOST.length) as IRIstub : resultingRoute
}

/**
 * Returns a parameter from the current URL if they are string-able.
 *
 * @param router a given router
 * @param param the expected parameter of the url
 * @returns the value of the parameter, if it is a string, otherwise null
 */
export const getStringFromQuery = (router: NextRouter, param: string): string => {
  const value = router.query[param]
  return typeof value === "string" ? value : null
}

/**
 * Returns a parameter from the current URL as an integer bigger than 0 to be used as ID.
 *
 * @param router a given router
 * @param param the expected parameter of the url
 * @returns the value of the parameter if it is convertable to an integer bigger than 0, otherwise null
 */
export const getIdFromQuery = (router: NextRouter, param: string): number =>
  stringToId(getStringFromQuery(router, param))

/**
 * Removes all query parameters from the current URL.
 * currently unused
 *
 * @param router a given router
 */
export const removeAllQueryParams = (router: NextRouter): void => {
  void router.replace(
    router.pathname,
    router.pathname,
    { shallow: true }
  )
}

/**
 * Returns a regex pattern of the given route to be used to test if links match the route.
 * Usage:
 * routeRegexPattern(Routes.AdminUserDetails).test("/management/users/8")
 *
 * @param route route to be returned as regex pattern
 * @returns RegExp that may be used to test a string for matching
 *
 * NOTE: that does work for most of the routes but not for all yet: @see tests
 */
export const routeRegexPattern = (route: Routes): RegExp =>
  // replacing all [...] by regex .* to test afterward if the resulting regex matches a given link string
  new RegExp("^" + route.replaceAll(/\[\w+\]/g, "[^/]+") + "$")


/**
 * @param link string that should match the given route
 * @param route route which pattern should the link match
 * @returns returns true, if a given link matches a given route pattern
 */
export const linkMatchesRoute = (link: string, route: Routes): boolean =>
  // replacing all [...] by regex .* to test afterward if the resulting regex matches the href found on a link element
  !!link && !!route && routeRegexPattern(route)
    .test(link)

// #endregion helper functions

// #region hooks

/**
 * Returns a login link with an encoded redirectBack-Parameter or, if no redirectBack param is given,
 * the URL of the current page as redirectBack link
 *
 * Provided as hook b/c window must be initialized when page is loaded
 *
 * @param redirectBack a URL where the user should be sent after login
 * @returns a valid login link with redirectBack param
 */
export const useLoginRouteWithRedirectBack = (redirectBack?: string): string => {
  const location = useWindowLocation()
  const locationUrl = location ? location.pathname + location.search + location.hash : ""

  if (redirectBack) {
    return Routes.Login + "?redirectBack=" + encodeURIComponent(redirectBack)
  } else {
    return Routes.Login + (locationUrl ? "?redirectBack=" + encodeURIComponent(locationUrl) : "")
  }
}

// endregion hooks