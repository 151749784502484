import { takeLatest, select, put, call, all } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { iriFromIModelOrIRI } from "@api/entityTypeEndpointDefinitions"
import { IProject, IProjectCreation, IProjectMembership, IProjectFollowership, ProjectProgress, MembershipRole, IModel, IUser } from "@api/schema"
import { IUserWriteDTO, transformEntityToWriteDTO, IProjectFollowershipWriteDTO } from "@api/schema-dto"
import { Routes } from "@basics/routes"
import { addNotificationAction, IAddNotificationAction } from "@redux/actions/notifications"
import { RegistrationUsecases, IProcessOnboardingDataBeforeRegistrationAction, IProcessOnboardingDataAfterRegistrationAction } from "@redux/actions/registration"
import { createModelSuccessAction, newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction } from "@redux/helper/actions"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { EntityType } from "@redux/reduxTypes"
import { getCurrentUser } from "@redux/saga/currentUser"
import { IProcessOnboardingDataAfterLoginAction } from "@redux/usecases/userAccount/actions"
import { UserAccountUsecases } from "@redux/usecases/userAccount/definitions"
import { prefixedKey } from "@services/i18n"
import { routeWithParams } from "@services/routesHelper"
import { SubmissionError } from "@services/submissionError"


import { resetOnboardingStateAction } from "./actions"
import { OnboardingType, getEntityTypeFromOnboardingType } from "./definitions"
import { selectOnboardingType, selectOnboardingTypeNewIdea, selectOnboardingTypeNewProjectFollowership, selectOnboardingTypeNewProjectFromIdea, selectOnboardingTypeNewProjectMemberApplication } from "./selectors"

export function* onboardingWatcherSaga(): any {
  yield all([
    takeLatest(RegistrationUsecases.ProcessOnboardingDataBeforeRegistration, withCallback(preRegistrationForOnboardingSaga)),
    takeLatest(RegistrationUsecases.ProcessOnboardingDataAfterRegistration, postRegistrationForOnboardingSaga),
    takeLatest(UserAccountUsecases.ProcessOnboardingDataAfterLogin, postLoginForOnboardingSaga),
  ])
}

// #region pre registration handling

/**
 * Before a user registration request is sent to the API, check if they previously created a any object of OnboardingType,
 * if yes attach this information to the user object that is passed to the API.
 *
 * @param action IUserRegistrationUpcomingAction
 */
function* preRegistrationForOnboardingSaga(action: IProcessOnboardingDataBeforeRegistrationAction): Generator<any, IUserWriteDTO, any> {

  // NOTE this saga has no API requests, therefore no try/catch (TODO: check if that's a valid pattern/reasoning)
  // It does not need any API requests, since it's just logic building the onboarding data.
  // As such, it IS asynch and it DOES need access to state data (via asynch `yield select`), so it NEEDs to be a Saga (afaik).

  const onboardingType: OnboardingType = yield select(selectOnboardingType)
  if (onboardingType === OnboardingType.None) {
    return action.userWriteDTO
  }

  const userWriteDTO: IUserWriteDTO = {
    ...action.userWriteDTO,
    createdProjects: [],
    projectMemberships: [],
    followership: undefined,
  }

  switch (onboardingType) {

    case OnboardingType.NewIdea:
      const idea: IProject = yield select(selectOnboardingTypeNewIdea)
      userWriteDTO.createdProjects.push(idea)
      break

    case OnboardingType.NewProjectFromIdea:
      const project: IProjectCreation = yield select(selectOnboardingTypeNewProjectFromIdea)
      userWriteDTO.createdProjects.push(project)
      break

    /**
     * @todo multi refactor Onboarding, considering following ticket:
     * @see https://futureprojects.atlassian.net/browse/FCP-1759
     */
    case OnboardingType.NewProjectMemberApplication:
      const memberApplication: IProjectMembership = yield select(selectOnboardingTypeNewProjectMemberApplication)
      userWriteDTO.projectMemberships.push(memberApplication)
      break

    case OnboardingType.NewProjectFollowership:
      const followership: IProjectFollowership = yield select(selectOnboardingTypeNewProjectFollowership)
      userWriteDTO.followership = transformEntityToWriteDTO(EntityType.ProjectFollowership, followership)
      break
  }

  return userWriteDTO
}

// #endregion

// #region post registration handling

/**
 * After a user registered successfully, check if they previously created any object of OnboardingType,
 * if yes add notifications and reset the state.
 *
 * @param action IUserRegistrationCompletedAction
 */
function* postRegistrationForOnboardingSaga(action: IProcessOnboardingDataAfterRegistrationAction): Generator<any, void, any> {

  // NOTE this saga has no API requests, therefore no try/catch (TODO: check if that's a valid pattern/reasoning)

  if (action.user.createdProjects) {
    for (const project of action.user.createdProjects) {

      // for idea
      if (project.progress === ProjectProgress.Idea) {
        yield put(addNotificationAction("message.project.ideaSaved", "success"))
      }

      // for project
      if (project.progress === ProjectProgress.CreatingProfile) {
        yield put(addNotificationAction("message.project.newProjectSaved", "success"))
        yield put(createModelSuccessAction(EntityType.Project, project))
      }
    }
  }

  // for memberApplication
  /**
   * @todo multi: umbauen auf UOR
   *
   * @see https://futureprojects.atlassian.net/browse/FCP-1509
   */
  if (action.user.projectMemberships?.some((m) => m.role === MembershipRole.Applicant)) {
    yield put(addNotificationAction("message.project.memberships.saved", "success"))
  }

  // for followership
  if (action.user.followerships?.length > 0) {
    yield put(addNotificationAction(prefixedKey("follow-project", "saved"), "success"))
  }

  yield put(resetOnboardingStateAction())
}

// #endregion

// #region post login handling
/**
 * After a user logged in successfully, check if they previously created any object of OnboardingType,
 * if yes send the data to the API, add notifications and reset the state.
 *
 * @param action IUserLoginCompletedAction
 */
function* postLoginForOnboardingSaga(action: IProcessOnboardingDataAfterLoginAction): Generator<any, IModel, any> {

  const usecaseKey = action.type // @TODO fixme: usecaseKey may be refactored into action, see loadModelAction etc

  const onboardingType: OnboardingType = yield select(selectOnboardingType)
  if (onboardingType === OnboardingType.None) {
    return
  }

  const entityType = getEntityTypeFromOnboardingType(onboardingType)

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey))

    let resultingObject: IModel = null
    let successfullyCreatedNewObject: boolean = null
    let notificationAction: IAddNotificationAction = null

    switch (onboardingType) {
      case OnboardingType.NewIdea:
        const onboardingIdea: IProject = yield select(selectOnboardingTypeNewIdea)

        resultingObject = yield call(apiClient.createProject, onboardingIdea)
        successfullyCreatedNewObject = true

        notificationAction =
          addNotificationAction(
            {
              messageKey: "message.project.ideaSaved",
              linkTitleKey: "goto.newProject",
              // @todo multi migration onboarding
              route: routeWithParams(Routes.CreateProjectFromIdea, { id: "programId", ideaId: (resultingObject as IProject).id })
            },
            "success",
            { autoClose: false }
          )

        break

      case OnboardingType.NewProjectFromIdea:
        const onboardingProject: IProjectCreation = yield select(selectOnboardingTypeNewProjectFromIdea)

        resultingObject = yield call(apiClient.createProject, onboardingProject)
        notificationAction = addNotificationAction("message.project.newProjectSaved", "success")
        successfullyCreatedNewObject = true
        break

      /**
       * @todo multi refactor Onboarding, considering following ticket:
       * @see https://futureprojects.atlassian.net/browse/FCP-1759
       */
      case OnboardingType.NewProjectMemberApplication:
        const onboardingMemberApplication: IProjectMembership = yield select(selectOnboardingTypeNewProjectMemberApplication)

        // since there may already exist a projectMembership between the user and the project, we must check this first
        const userForMemberApplication: IUser = yield call(getCurrentUser)
        const oldProjectMembershipOftheUser = userForMemberApplication.projectMemberships?.find((membership: IProjectMembership) =>
          iriFromIModelOrIRI(membership.project) === iriFromIModelOrIRI(onboardingMemberApplication.project))

        if (!oldProjectMembershipOftheUser) {
          // the user did not already apply for the project
          onboardingMemberApplication.user = userForMemberApplication["@id"]

          resultingObject = yield call(apiClient.createEntity, EntityType.ProjectMembership, onboardingMemberApplication)

          notificationAction = addNotificationAction("message.project.memberships.saved", "success")
          successfullyCreatedNewObject = true
        } else {
          resultingObject = oldProjectMembershipOftheUser

          notificationAction = addNotificationAction(
            "message.project.memberships." +
              (oldProjectMembershipOftheUser.role === onboardingMemberApplication.role)
              ? "applicationAlreadyExists"
              : "otherMembershipAlreadyExists"
            , "info"
            , { autoClose: false }
          )
          successfullyCreatedNewObject = false
        }
        break

      case OnboardingType.NewProjectFollowership:
        const onboardingProjectFollowership: IProjectFollowership = yield select(selectOnboardingTypeNewProjectFollowership)

        // since there may already exist a followership between the user and the project, we must check this first
        const userForFollowership: IUser = yield call(getCurrentUser)
        const oldFollowershipOftheUser = userForFollowership.followerships?.find((followership: IProjectFollowership) =>
          iriFromIModelOrIRI(followership.project) === iriFromIModelOrIRI(onboardingProjectFollowership.project))

        if (!oldFollowershipOftheUser) {
          // the user did not already apply for the project
          onboardingProjectFollowership.user = userForFollowership["@id"]

          // transform entity to its DTO representation, if applicable
          const onboardingProjectFollowershipDTO: IProjectFollowershipWriteDTO = transformEntityToWriteDTO(EntityType.ProjectFollowership, onboardingProjectFollowership)

          resultingObject = yield call(apiClient.createEntity, EntityType.ProjectFollowership, onboardingProjectFollowershipDTO)

          notificationAction = addNotificationAction(prefixedKey("follow-project", "saved"), "success")
          successfullyCreatedNewObject = true
        } else {
          resultingObject = oldFollowershipOftheUser

          notificationAction = addNotificationAction(prefixedKey("follow-project", "alreadyExists"), "info", { autoClose: false })
          successfullyCreatedNewObject = false
        }
        break
    }

    yield put(resetOnboardingStateAction())

    if (successfullyCreatedNewObject) {
      yield put(createModelSuccessAction(entityType, resultingObject))
    }

    // This is old code => should be replaced by refreshing the UOR,
    // if a project or a followership has been created
    // refresh user object and list of own projects
    // @todo prüfen, ob die beiden Aufrufe relevant sind; ggf abgleichen mit generalSaga.refreshConnectedEntities
    // yield putWait(loadCurrentUserAction())
    // yield putWait(newLoadCollectionAction(EntityType.Project, null, ScopeTypes.MyProjects))

    yield put(notificationAction)

    yield put(newSingleEntityUsecaseRequestSuccessAction(entityType, usecaseKey, resultingObject))

    if (action.onSuccess) {
      yield call(action.onSuccess, onboardingType, resultingObject)
    }

    return resultingObject
  } catch (err) {

    let errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    // We need a special error handling here, since we do not have direct access to a form
    // (although it's still visible for the user on the login page).
    // We won't be using setErrors as in other sagas.

    // Therefore we firstly determine the one/single errorMessage
    if (err instanceof SubmissionError) {
      // if we got more than 1 message then use general message
      const keys = Object.keys(err.errors)
      if (keys.length === 1 && typeof err.errors[keys[0]] === "string") {
        errorMessage = err.errors[keys[0]]
      }
    }

    // We want to show a generic error message as Toast/notification.
    yield put(addNotificationAction("error:validate.general.submissionFailed", "error"))

    // Additionally, we'll call a possible onError callback with the (probably more technical) error message.
    if (action.onError) {
      yield call(action.onError, errorMessage)
    }

    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey, errorMessage))

    return null
  }
}
// #endregion